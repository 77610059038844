import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Flights from "./pages/Flights";
import Hotel from "./pages/Hotel";
import Bus from "./pages/Bus";
import Train from "./pages/Train";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import UserLogin from "./components/user/UserLogin"
import UserSignUp from "./components/user/UserSignUp"
import './components/flight/flight.css'
import './components/common/common.css'
import './components/home/home.css'
import './components/common/nav.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './components/common/responsive.css'
import './components/common/common.css'
import UserRegisterOtp from "./components/user/UserRegisterOtp";
import UserRegisterSuccess from "./components/user/UserRegisterSuccess";
import UserForgetPassword from "./components/user/UserForgetPassword";
import { RestrictedRoute } from "./Auth/restricted-route";
import { PopularPlaceDetail } from "./components/PopularPlace/PopularPlaceDetail";
import { HotelList } from "./components/Hotel/HotelList";
import { HotelPayment } from "./components/Hotel/HotelPayment";
import { HotelResult } from "./components/Hotel/HotelResult";
import { Blog } from "./pages/Blog";
import { BlogLogin } from './pages/BlogLogin'
import { BlogList } from "./components/Blog/BlogList";
import { BlogDetail } from "./components/Blog/BlogDetail";
import { CreateBlog } from "./components/Blog/CreateBlog";
import { CreatedBlogList } from "./components/Blog/CreatedBlogList";
import UserOtpMail from "./components/user/UserOtpMail";
import { BookingReview } from "./components/flight/BookingReview";
import { TripDetails } from "./components/flight/TripDetails";
import { Profile } from './components/user/Profile'
import { FlightBookingData } from "./components/user/FlightBookingData";
import { HotelBookingData } from "./components/user/HotelBookingData";
import { FlightTickerDetails } from "./components/user/FlightTickerDetails";
import { HotelBookingDetail } from "./components/user/HotelBookingDetail";
import setAuthToken from "./utils/setAuthToken";
import { PnrCancel } from "./components/user/PnrCancel";
import { PnrCancelOtp } from "./components/user/PnrCancelOtp";
import { HotelBookingConfirm } from "./components/Hotel/HotelBookingConfirm";
import { HotelGuestCancel } from "./components/user/HotelGuestCancel";
import { HotelGuestCancelOtp } from "./components/user/HotelGuestCancelOtp";
import { PrivateRoute } from "./Auth/PrivateRoute";
import Wrapper from "./components/common/wrapper";
import { ContactUs } from "./components/common/ContactUs";
import { AboutUs } from "./components/common/AboutUs";
import { Career } from "./components/common/Career";
import { TermAndCondition } from "./components/common/TermAndCondition";
import { PrivacyAndPolicy } from "./components/common/PrivacyAndPolicy";
import { Faqs } from "./components/common/Faqs";
import { GuestFlightRestrict } from "./Auth/GuestFlightRestrict";
import { GuestHotelRestrict } from "./Auth/GuestHotelRestrict";
import Facebook from "./components/flight/facebook";
import Google from "./components/flight/google";
import { TripCancel } from "./components/common/TripCancel";
import { BookingResponse } from "./components/common/BookingResponse";
// import jQuery from 'jquery';


function App() {
  if (localStorage.getItem('tickatrip-token')) {
    setAuthToken(localStorage.getItem('tickatrip-token'));
    
  } else {
    setAuthToken(null);

  }
  return (

    <div>
      <Provider store={store} >
        <div>
          <BrowserRouter >
            <Routes>
              {/* hotel */}

              <Route path="/" element={<Wrapper children={<Home />} mode='hotel' />} />

              <Route path="/hotel" element={<Wrapper children={<Hotel />} mode='hotel' />} />

              <Route path="/hotel-list" element={<Wrapper children={<HotelList />} mode='hotel' />} />

              <Route path="/hotel-result" element={<Wrapper children={<HotelResult />} mode='hotel' />} />

              <Route path="/hotel-booking-confirm" element={<Wrapper children={<HotelBookingConfirm />} mode='hotel' />} />

              <Route path="/trip-cancel" element={<Wrapper children={<TripCancel />} mode='user' />} />


              {/* common */}

              <Route path="/auth/google" element={<Google />} />
              <Route path="/auth/facebook" element={<Facebook />} />
              <Route path="/booking-response" element={<BookingResponse />} />
              {/* <Route path="/hotel-booking-response" element={<Google/>} /> */}

              {/* flight */}

              <Route path="/flight" element={<Wrapper children={<Flights />} mode='flight' />} />

              <Route path="/trip-detail" element={<Wrapper children={<TripDetails />} mode='flight' />} />
              <Route path="/popular-place-detail" element={<PopularPlaceDetail />} />
              <Route path="/hotel-payment" element={<Wrapper children={<HotelPayment />} mode='hotel' />} />

              <Route element={<PrivateRoute />}>
                <Route path="/profile" element={<Wrapper children={<Profile />} mode='user' />} /> {/* passed */} {/* common */}
                <Route path="/register-success" element={<UserRegisterSuccess />} mode='user' /> {/* passed */} {/* common */}
                <Route path="/flight-booking" element={<Wrapper children={<FlightBookingData />} mode='user' />} /> {/* flight */}
                <Route path="/hotel-booking" element={<Wrapper children={<HotelBookingData />} mode='user' />} /> {/* hotel */}
                <Route path="/flight-ticket" element={<Wrapper children={<FlightTickerDetails />} mode='user' />} /> {/* flight */}
                <Route path="/hotel-booking-detail" element={<Wrapper children={<HotelBookingDetail />} mode='user' />} /> {/* hotel */}
              </Route>

              <Route element={<RestrictedRoute />}>
                <Route path="/forget-mail" element={<UserOtpMail />} />    {/* passed */} {/* common */}
                <Route path="/login" element={<UserLogin />} />  {/* passed */} {/* common */}
                <Route path="/signup" element={<UserSignUp />} /> {/* passed */} {/* common */}
                <Route path="/forget-otp" element={<UserRegisterOtp />} /> {/* passed */} {/* common */}
                <Route path="/forget-password" element={<UserForgetPassword />} /> {/* passed */} {/* common */}
              </Route>

              <Route element={<GuestFlightRestrict />}>
                <Route path="/pnr-cancel" element={<Wrapper children={<PnrCancel />} mode='flight' />} />
                <Route path="/pnr-otp" element={<Wrapper children={<PnrCancelOtp />} mode='flight' />} />
              </Route>

              <Route element={<GuestHotelRestrict />}>
                <Route path="/hotel-guest-cancel" element={<Wrapper children={<HotelGuestCancel />} mode='hotel' />} />
                <Route path="/hotel-guest-otp" element={<Wrapper children={<HotelGuestCancelOtp />} mode='hotel' />} />
              </Route>

              <Route path="/review" element={<BookingReview />} /> {/* low prioriy */}
              <Route path="/bus" element={<Bus />}></Route> {/* low prioriy */}
              <Route path="/blog" element={<Blog />} /> {/* low prioriy */}
              <Route path="/blog-login" element={<BlogLogin />} /> {/* low prioriy */}
              <Route path="/blog-list" element={<BlogList />} /> {/* low prioriy */}
              <Route path="/blog-detail" element={<BlogDetail />} /> {/* low prioriy */}
              <Route path="/create-blog" element={<CreateBlog />} /> {/* low prioriy */}
              <Route path="/created-blog-list" element={<CreatedBlogList />} /> {/* low prioriy */}
              <Route path="/train" element={<Train />} />{/* low prioriy */}
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/career" element={<Career />} />
              <Route path="/term-condition" element={<TermAndCondition />} />
              <Route path="/privacy-policy" element={<PrivacyAndPolicy />} />
              <Route path="/faqs" element={<Faqs />} />
            </Routes>
          </BrowserRouter>
          <div>

          </div>
        </div>
      </Provider>

    </div>
  );
}

export default App;
