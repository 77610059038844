import React, { useEffect, useState } from 'react';
import popularBanner from '../../asset/hotel/2.jpg'
import { Container, makeStyles, Typography } from "@material-ui/core";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import PinDropIcon from '@mui/icons-material/PinDrop';
import { Modal } from 'react-bootstrap';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useDispatch, useSelector } from 'react-redux';
import hotelActions from '../../redux/hotel/actions';
import moment from 'moment';
import { PROFILE_URL } from '../../utils/profileConst';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ApartmentIcon from '@mui/icons-material/Apartment';
import commonActions from '../../redux/common/actions';
import HorizontalCard from './HorizontalCard';
import loaderGifImg from "../../asset/loader_hotel.gif";
function Item(props) {
    const { sx, ...other } = props;
    return (
        <Box
            {...other}
        />
    );
}

Item.propTypes = {
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
        ),
        PropTypes.func,
        PropTypes.object,
    ]),
};
const useStyles = makeStyles((theme) =>
({
    popularBanner: {
        backgroundImage: `url(${popularBanner})`,
        backgroundRepeat: 'no-repeat',
        width: ' 100%',
        height: '100%',
        minHeight: '50vh',
        maxHeight: '50vh',
        backgroundSize: ' cover',
        backgroundPosition: ' center center',
        position: ' relative',
    },
    root: {
        width: '100%',
    },
    flightname: {
        color: '#000',
        fontSize: '15px',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        alignItems: 'center',
    },
    column: {
        flexBasis: '25%',
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
})
)

export const HotelResult = () => {
    const validationSchema = Yup.object().shape({
        description: Yup.string()
            .required('Description is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState, reset } = useForm(formOptions);
    const { errors } = formState;
    const classes = useStyles();
    const dispatch = useDispatch();
    const { hotelDetails, reviews, nextPageUrl, initialHotelDetails, hotelRoomRate } = useSelector(
        (state) => state.hotelReducer
    );
    const { loading } = useSelector(
        (state) => state.commonReducer
    );

    const value = parseInt(hotelDetails?.hotelRating) ? parseInt(hotelDetails?.hotelRating) : 0;

    const [hotelImages, setHotelImages] = useState([])
    const [hotelFecility, setHotelFecility] = useState([])
    const [editMode, setEditMode] = useState(false);
    const [show, setShow] = useState(false);
    const [editId, setEditId] = useState(null);
    const [ratingValue, setRatingValue] = useState(1);
    
    const onSubmit = (data) => {
        if (editMode) {
            dispatch({
                type: hotelActions.UPDATE_HOTEL_REVIEW, payload: {
                    "type": "Hotel",
                    "hotelId": hotelDetails.hotelId,
                    "description": data.description,
                    "rating": ratingValue
                }, id: editId
            })
        } else {
            dispatch({
                type: hotelActions.CREATE_HOTEL_REVIEW, payload: {
                    "type": "Hotel",
                    "hotelId": hotelDetails.hotelId,
                    "description": data.description,
                    "rating": ratingValue
                }
            })
        }
    }

    const handleDeleteReview = (id) => {
        dispatch({ type: hotelActions.DELETE_HOTEL_REVIEW, payload: { id: id, hotelId: hotelDetails.hotelId } })
    }

    const OnbookingDetail = (e) => {
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        dispatch({
            type: hotelActions.GET_HOTEL_RATE, payload: initialHotelDetails
        })
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        setShow(true)
    }

    const clearEdit = () => {
        setEditMode(false);
        setEditId(null);
        reset({
            'description': ""
        })
    }

    const showMore = (id, description) => {
        dispatch({ type: hotelActions.GET_HOTEL_REVIEWS, payload: { type: 'Hotel', hotelId: hotelDetails.hotelId }, initial: false })
    }

    const handleEditReview = (el) => {
        setEditId(el.id);
        setEditMode(true);
        setRatingValue(el.rating);
        reset({
            'description': el.description
        })
    }

    useEffect(() => {
        setHotelImages(hotelDetails?.hotelImages)
        setHotelFecility(hotelDetails?.facilities);
        dispatch({ type: hotelActions?.GET_HOTEL_REVIEWS, payload: { type: 'Hotel', hotelId: hotelDetails?.hotelId }, initial: true })
    }, [hotelDetails]);

    useEffect(() => {
        setEditMode(false);
        setEditId(null);
        reset({
            'description': ""
        });
        setRatingValue(5);
    }, [reviews]);

    useEffect(() => {
        return () => {
            dispatch({ type: hotelActions.SET_HOTEL_REVIEWS, payload: [], page: null });
        }
    }, []);

    return (
        <>
            {loading ?
                <div>
                    <div className='blur_bg'></div>
                    <div className='loaderGif'>
                        <img src={loaderGifImg} alt='' />
                    </div>
                </div>
                :
                null
            }
            <div className={classes.popularBanner}>
                <div className='overlay'></div>
            </div>
            <div className='popular_search'>
                <Container>
                    <div className='result_hotel_name_head'>
                        <div className='result_hotel_name'>
                            <Typography variant='h2'>{hotelDetails?.name}</Typography>
                            <Rating
                                name="hover-feedback"
                                value={value}
                                precision={0.5}
                                readOnly
                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                            />
                        </div>
                        <div className='result_hotel_location'>
                            <PinDropIcon />
                            <Typography variant='h6'>
                                {hotelDetails?.address}
                            </Typography>
                        </div>

                    </div>
                </Container>
            </div>
            <div className='popular_detail_con hotel_list hotel_result'>
                <Box sx={{ flexGrow: 1 }}>
                    <Container>
                        <Grid className='row'>
                            <Grid className='col-12'>
                                <div className='hotel_images'>
                                    <div className='container'>
                                        <div className='row '>
                                            {hotelImages?.length > 5 ? hotelImages?.slice(0, 6)?.map((item, index) => (
                                                <div className='col-lg-4 clo-md-4 col-sm-6' key={"hotelImages" + index}>
                                                    <img src={item.url} alt='img' />
                                                </div>
                                            )) : hotelImages?.map((item, index) => (
                                                <div className='col-lg-4 clo-md-4 col-sm-6' key={"hotelImages" + index}>
                                                    <img src={item.url} alt='img' />
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid className='col-md-9 col-lg-9 col-sm-12 col-12'>
                                <Box className='result_hotel_details_sec'>
                                    <Box className='result_hotel_details_td'>
                                        <Typography variant='h6'>Description</Typography>
                                    </Box>
                                    <p dangerouslySetInnerHTML={{ __html: hotelDetails?.description?.content }} /><Box className='result_hotel_images'>

                                    </Box>
                                </Box>
                                <div className='detai_booking'>
                                    <Button className='hotel_btn' onClick={((e) => OnbookingDetail(e))}>
                                        Book Now
                                    </Button>
                                </div>

                                <Box className='room_review'>
                                    <Box className='result_hotel_details_td'>
                                        <Typography variant='h6'>Room Review</Typography>
                                    </Box>
                                    {localStorage.getItem('tickatrip-token') && reviews.length > 0 &&
                                        reviews?.map((el, ind) => {
                                            return (
                                                <Box className='room_review_inner' key={"reviews" + ind}>
                                                    <img src={`${PROFILE_URL}${el?.user?.profile_image}`} alt='img' />
                                                    <div className='room_review_inner_con'>
                                                        <Box className='review_user_name'>
                                                            <h6>{el?.user?.username}</h6>
                                                            {/* <span>{moment(el.updated_at).format('YYYY-MM-DD')}</span> */}
                                                            <span>{moment(new Date(el?.updated_at)).fromNow()}</span>
                                                        </Box>
                                                        <div className='reiew_lists'>
                                                            <Rating
                                                                name="text-feedback"
                                                                value={el?.rating}
                                                                readOnly
                                                                precision={0.5}
                                                            />
                                                            <p>{el?.description}</p>
                                                        </div>
                                                        <div className='edit_update_review'>
                                                            {(localStorage.getItem('username') === el?.user?.username) && !editMode && <button onClick={() => handleEditReview(el)}><EditIcon /></button>}
                                                            {(localStorage.getItem('username') === el?.user?.username) && !editMode && <button onClick={() => handleDeleteReview(el?.id)}><DeleteIcon /></button>}
                                                        </div>
                                                    </div>
                                                </Box>
                                            );
                                        })
                                    }

                                    {nextPageUrl &&
                                        <Box className='room_review_inner_btn'><button onClick={() => showMore()}>Show More Reviews</button>
                                        </Box>}
                                    {localStorage.getItem('tickatrip-token') ?
                                        <Box className='review_commend_form'>
                                            <Typography className='hotel_result_sm_td' variant='h6'>Leave a Rating & Comment</Typography>
                                            <Rating
                                                name="text-feedback"
                                                value={ratingValue ? ratingValue : 0}
                                                onChange={(e, value) => {
                                                    setRatingValue(value);
                                                }}
                                                precision={0.5}
                                            />
                                            <form onSubmit={handleSubmit(onSubmit)} className='payment_form_details'>
                                                <Box sx={{ flexGrow: 1 }}>
                                                    <Grid container spacing={2}>
                                                        {/* <Grid item xs={4}>
                                                            <div className="form-group">
                                                                <input placeholder='Name' name="name" type="text" {...register('name')} className={`form-control ${errors.name ? 'is-invalid' : ''}`} />
                                                                <div className="invalid-feedback">{errors.name?.message}</div>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <div className="form-group col">
                                                                <input placeholder='Email' name="email" type="text" {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                                                                <div className="invalid-feedback">{errors.email?.message}</div>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <div className="form-group col">
                                                                <input placeholder='Phone Number' name="phone" type="number" {...register('phone')} className={`form-control ${errors.phone ? 'is-invalid' : ''}`} />
                                                                <div className="invalid-feedback">{errors.phone?.message}</div>
                                                            </div>
                                                        </Grid> */}
                                                        <Grid item xs={12}>
                                                            <div className="form-group">
                                                                <textarea
                                                                    name="commentTextArea"
                                                                    type="text"
                                                                    rows="6"
                                                                    maxLength={255}
                                                                    placeholder='Write message'
                                                                    id="CommentsOrAdditionalInformation"
                                                                    {...register('description')} className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                                                                >
                                                                </textarea>
                                                                <div className="invalid-feedback">{errors.description?.message}</div>
                                                            </div>
                                                        </Grid>
                                                        {!editMode ?
                                                            <Grid item xs={12}> <Button className='submit' type="submit">submit</Button></Grid>
                                                            : <>
                                                                <Grid item xs={4}> <Button variant="danger" className='submit' onClick={clearEdit}>clear</Button></Grid>
                                                                <Grid item xs={4}> <Button variant="primary" className='submit' type="submit">Update</Button></Grid>
                                                            </>}
                                                    </Grid>
                                                </Box>
                                            </form>
                                        </Box>
                                        :
                                        null
                                    }

                                </Box>
                            </Grid>
                            <Grid className='col-md-3 col-lg-3 col-sm-12 col-12'>
                                {/* <Box className='hotel_result_inner_search' sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Form.Group>
                                                <Form.Label>Check - In</Form.Label>
                                                <Form.Control type="date" placeholder="Date of Birth" />
                                            </Form.Group>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Form.Group>
                                                <Form.Label>Check - Out</Form.Label>
                                                <Form.Control type="date" placeholder="Date of Birth" />
                                            </Form.Group>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Form.Group>
                                                <Form.Label>Rooms and Guests</Form.Label>
                                                <Select options={options} placeholder={<GroupOutlinedIcon />} />
                                            </Form.Group>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Form.Group>
                                                <Form.Label>Guests</Form.Label>
                                                <Select options={options} placeholder={<AddLocationAltOutlinedIcon />} />
                                            </Form.Group>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Button className="search_btn_banner" variant="outlined" startIcon={<Search />}>
                                                Search
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box> */}
                                <Box className='amenties_box'>
                                    <Box className='result_hotel_details_td'>
                                        <Typography variant='h6'>Amenities</Typography>
                                    </Box>
                                    <Box className='amenties_box_list'>
                                        <ul>
                                            {hotelFecility?.map((item, index) => (
                                                <li key={'hotelFecility' + index}><div className='amenities_icon'><ApartmentIcon /></div>{item}</li>
                                            ))}
                                        </ul>
                                    </Box>
                                </Box>
                                {/* <Box className='extra_searvice'>
                                    <Box className='result_hotel_details_td'>
                                        <Typography variant='h6'>Extra Service</Typography>
                                    </Box>
                                    <Box className='extra_searvice_inner'>
                                        <div className='extra_searvice_list'>
                                            <p>Free Internet</p> <span>Rs : 200</span>
                                        </div>
                                        <div className='extra_searvice_list'>
                                            <p>Air Conditioner</p> <span>Rs : 200</span>
                                        </div>
                                        <div className='extra_searvice_list'>
                                            <p>LCD Television</p> <span>Rs : 200</span>
                                        </div>
                                        <div className='extra_searvice_list'>
                                            <p>Microwave</p> <span>Rs : 200</span>
                                        </div>
                                        <div className='extra_searvice_list'>
                                            <p>IRON Box</p> <span>Rs : 200</span>
                                        </div>
                                    </Box>
                                </Box> */}
                                {/* <Box className='hotel_result_blog'>
                                    <Box className='result_hotel_details_td'>
                                        <Typography variant='h6'>Similar Blog <Button className='submit blog_btn'>229 Trip Blog</Button></Typography>
                                    </Box>
                                    <Box className='hotel_result_blog_slider'>
                                        <BlogCard />
                                    </Box>
                                </Box> */}
                            </Grid>

                        </Grid>
                    </Container>
                </Box>
                <div className='hotel_images'>
                    <div className='container'>
                        <div className='row '>
                            {hotelImages?.map((item, index) => (
                                <div className='col-lg-4 clo-md-4 col-sm-6' key={"hotelImages" + index}>
                                    <img src={item.url} alt='img' />
                                </div>
                            ))}

                        </div>
                    </div>
                </div>

                {/* <Box className='result_related_hotels'>
                    <Container>
                        <Typography variant='h2'>related hotels</Typography>
                        <Box className='related_hotel_slider'>
                            <OwlCarousel className='owl-theme experienceSlide' {...optionsSlider}>
                                <div className='item'>
                                </div>
                            </OwlCarousel>
                        </Box>
                    </Container>
                </Box> */}
            </div>
            <Modal
                show={!loading && show && hotelRoomRate?.length > 0 && !loading}
                onHide={() => setShow(false)}
                onClose={() => setShow(false)}
                className='booking_model_pop'
                centered

            >
                <Modal.Header closeButton>
                    <h3>Select Your Room</h3>
                </Modal.Header>
                <Modal.Body>
                    <div
                    // onBlur={(e) => {
                    //     if (!e.currentTarget.contains(e.relatedTarget)) {
                    //         setToggle(!toggle);
                    //     }
                    // }}
                    >
                        {hotelRoomRate?.map((item, ind) => (
                            <div key={"hotelRoomRate" + ind}>
                                <HorizontalCard item={item} val={initialHotelDetails.val} index={ind} />
                            </div>
                        ))}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

