import { makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useDispatch, useSelector } from "react-redux";
import FlightContent from "../flight/Content";
import HotelSearch from '../Hotel/HotelSearch'
import ApartmentIcon from '@mui/icons-material/Apartment';
import FlightIcon from '@mui/icons-material/Flight';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import commonActions from "../../redux/common/actions";
import { API_IMG_URL } from '../../utils/constant';

const useStyles = makeStyles((theme) =>
({
    homebanner: {
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        paddingBottom: '2rem'
    },
    bannerTxtSmtop: {
        color: "#fff",
        [theme.breakpoints.down("xs")]: {
            fontSize: '18px ',
        },
    },
    bannerTxtcen: {
        color: "#fff",
        padding: "1rem 0",
        fontWeight: "bold",
        [theme.breakpoints.down("xs")]: {
            fontSize: '40px',
        },
    },
    radiobtns: {
        display: "flex",
    },
    bannerTxtSmbot: {
        color: "#fff",
        [theme.breakpoints.down("xs")]: {
            fontSize: '20px ',
        },
    },
    bannerTxtInner: {
        padding: "0rem 0 0 0",
        [theme.breakpoints.down("xs")]: {
            padding: "0rem 0 0 0",
        },
    },
    tabCase: {
        color: "#fff",
        textTransform: 'capitalize',
    },

})
)

const options = {
    margin: 10,
    responsiveClass: true,
    nav: false,
    loop: true,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 1,
        },
        1000: {
            items: 1,
        }
    },
};

function MenuTab() {
    const dispatch = useDispatch()
    const classes = useStyles();
    const { loading } = useSelector(
        (state) => state.commonReducer
    );
    const { homePageBanner } = useSelector((state) => state.commonReducer)

    const [value, setValue] = useState(1);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        dispatch({ type: commonActions.SET_LOADER, payload: true })
        dispatch({ type: commonActions.GET_HOME_BANNER, payload: [] })
    }, []);

    return (
        <div>
            {/* <div className="banner_overlay"></div> */}
            <div className='banner_sec'>
                {homePageBanner?.length > 0 &&
                    <OwlCarousel className='banner_slider owl-theme' {...options}>
                        {homePageBanner?.map((val, index) => (
                            <div className={`item home_banner_box ${classes.homebanner} `} style={{ backgroundImage: `url(${`${API_IMG_URL}/server/homePageSlider/${val.image}`})` }}>
                                <div >
                                    <div className={`container ${classes.bannerTxtInner} `}>
                                        <div className='bannercon'>
                                            <Typography className={classes.bannerTxtSmtop} variant="h3">
                                                {val.line1}
                                            </Typography>
                                            <Typography className={classes.bannerTxtcen} variant="h1">
                                                {val.line2}
                                            </Typography>
                                            <Typography className={classes.bannerTxtSmbot} variant="h5">
                                                {val.line3}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </OwlCarousel>
                }
                <div className="homesearch_box">
                    <div className='container'>
                        <div className='home_search_box_sec'>
                            <Box>
                                <TabContext value={value} >
                                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                        <TabList className="tb_bnl" onChange={handleChangeTab} >
                                            <Tab className="tab_titles" tabIndex={0} label={<span className={classes.tabCase}>Hotels</span>} value={1} />
                                            <Tab className="tab_titles" tabIndex={0} label={<span className={classes.tabCase}>Flights</span>} value={2} />
                                        </TabList>
                                    </Box>
                                    <TabPanel className='booking_select_tab' value={1} sx={{ textAlign: loading ? 'center' : 'inherit' }}>
                                        <div className="home_hotel_search_content">
                                            <HotelSearch />
                                        </div>
                                    </TabPanel>
                                    <TabPanel className='booking_select_tab' sx={{ textAlign: loading ? 'center' : 'inherit' }} value={2}>
                                        <FlightContent mode='Search' />
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </div>
                    </div>
                </div>
            </div >

        </div >
    )
}

export default React.memo(MenuTab);