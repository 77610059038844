import {
    takeEvery, call, all,
    put
} from 'redux-saga/effects';
import axios from 'axios';
import actions from './actions';
import { API_URL } from '../../utils/constant'
import commonActions from '../common/actions';
import userActions from './actions';
import setAuthToken from '../../utils/setAuthToken';

const UserSaga = function* () {
    yield all([
        yield takeEvery(actions.SET_ALL_REGISTER, registerUser),
        yield takeEvery(actions.USER_LOGIN, loginUser),
        yield takeEvery(actions.GET_OTP_MAIL_LOGIN, passwordReset),
        yield takeEvery(actions.GET_VERIFY_OTP, verifyOtp),
        yield takeEvery(actions.GET_NEW_PASSWORD, confirmPassword),
        yield takeEvery(actions.GET_PROFILE_EDIT, userEditUpdate),
        yield takeEvery(actions.GET_MY_BOOKING, userFlightBookingList),
        yield takeEvery(actions.GET_MY_BOOKING_CANCEL_VERIFY, ticketCancel),
        yield takeEvery(actions.GET_MY_BOOKING_CANCEL_CONFIRM, ticketCancelConfirm),
        yield takeEvery(actions.GET_MY_BOOKING_CANCEL_LIST, bookingCancelList),
        yield takeEvery(actions.GET_MY_BOOKING_TICKET_DETAILS, bookingTicketDetails),
        yield takeEvery(actions.GET_MY_HOTEL_BOOKINGS, getHotelBookings),
        yield takeEvery(actions.GET_MY_HOTEL_BOOKINGS_COMPLETED, getHotelBookingsCompleted),
        yield takeEvery(actions.GET_MY_HOTEL_BOOKINGS_CANCEL, getHotelBookingsCancel),
        yield takeEvery(actions.GET_HOTEL_BOOKINGS_CANCEL_REQUEST, getHotelBookingsCancelRequest),
        yield takeEvery(actions.GET_HOTEL_BOOKINGS_CANCEL_VERIFY, getHotelBookingsCancelVerify),
        yield takeEvery(actions.GET_HOTEL_BOOKINGS_GUEST_CANCEL_REG, getHotelGuestBookingsCancel),
        yield takeEvery(actions.GET_HOTEL_BOOKINGS_GUEST_CANCEL_OTP_VER, getHotelGuestBookingsCancelOtp),
        yield takeEvery(actions.GET_MY_FLIGHT_UP_BOOKING_DETAILS, getUpcomingFlightDetail),
        yield takeEvery(actions.GET_MY_FLIGHT_CANCEL_BOOKING_DETAILS, getCancelFlightDetail),
        yield takeEvery(actions.GET_MY_FLIGHT_COMPLETED_BOOKING_DETAILS, getCompletedFlightDetail),
        yield takeEvery(actions.GET_MY_COMPLETED_BOOKING, getMyCompletedBooking),
        yield takeEvery(actions.GET_MY_HOTEL_BOOKING_DETAILS, getMyHotelBookingDetails),
        yield takeEvery(actions.GET_MY_HOTEL_CANCEL_BOOKING_DETAILS, getMyHotelCancelBookingDetails),
        yield takeEvery(actions.GET_MY_HOTEL_COMPLETED_BOOKING_DETAILS, getMyHotelCompletedBookingDetails),
        yield takeEvery(actions.GET_USER_PAGE_SLIDER, getUserPageSlider),
        yield takeEvery(actions.LOG_OUT, logOut),
    ]);
};

const registerUser = function* (data) {
    const { userData, history } = data;
    var form_data = new FormData();

    for (var key in userData) {
        form_data.append(key, userData[key]);
    }
    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/register`,
                form_data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
            )
        );

        yield put({ type: actions.SET_AUTHETICATION, payload: true });
        localStorage.setItem('tickatrip-token', result.data.success.token);
        localStorage.setItem('email', result.data.success.user.email);
        localStorage.setItem('phone', result.data.success.user.phone);
        localStorage.setItem('username', result.data.success.user.username);
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'You have register successfully', status: "success" } });
        history('/register-success');
    } catch (err) {
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err.response.data.message, status: "failed" } });
    }
}
const loginUser = function* (data) {
    const { userData, history } = data;
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/login`,
                JSON.stringify(userData), {
                headers: {
                    "Content-Type": "application/json",
                },
            }
            )
        )
        
        setAuthToken(result.data.status.token)
        localStorage.setItem('tickatrip-token', result.data.status.token);
        localStorage.setItem('email', result.data.status.user.email);
        localStorage.setItem('phone', result.data.status.user.phone);
        localStorage.setItem('username', result.data.status.user.username);
        yield put({ type: actions.SET_AUTHETICATION, payload: true });
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: "Login successfully", status: "success" } });
        history('/');
    } catch (err) {
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Invalid login credentials', status: "failed" } });
    }
}

const logOut = function* (data) {
    const { history } = data;
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/logout`, {
                headers: {
                    "Content-Type": "application/json",
                },
            }
            )
        )
        if (result?.data?.status) {
            setAuthToken(null)
            localStorage.removeItem('tickatrip-token');
            localStorage.removeItem('email');
            localStorage.removeItem('phone');
            localStorage.removeItem('username');
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result?.data?.message, status: "success" } });
            history('/login');
        } else {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Invalid login credentials', status: "failed" } });
        }
    } catch (err) {
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Invalid login credentials', status: "failed" } });
    }
}


const passwordReset = function* (data) {
    const { validUserMail, history } = data;
    var form_data = new FormData();
    form_data.append('email', validUserMail.email);
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/sendPasswordRest`, form_data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
            )
        )
        if (result.status === 200 && result.data.status) {
            yield put({ type: actions.SET_VERIFY_OTP_ID, payload: result.data.message[1] });
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message[0], status: "success" } });
            yield put({
                type: actions.SET_OTP_MAIL_LOGIN_MESSAGE, payload: result.data.message[0]
            });
            history('/forget-otp');
        }
        else {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Some Error Occured', status: "failed" } });
        }
    } catch (err) {
        if (err.response.status === 400) {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err.response.data.message, status: "failed" } });
        }
    }
}

const verifyOtp = function* (data) {
    const { userOtp, history } = data;
    var form_data = new FormData();
    form_data.append('otp', userOtp.otp);
    form_data.append('email', userOtp.email);
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/verifyPasswordRest`, form_data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
            )
        )
        yield put({ type: actions.SET_VERIFY_OTP_ID_PASSWORD, payload: result.data.message[1] });
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message[0], status: "success" } });
        history('/forget-password');

    } catch (err) {
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err.response.data.message, status: "failed" } });
    }
}

const confirmPassword = function* (data) {
    const { userConfimPassword, history } = data;
    var form_data = new FormData();
    form_data.append('email', userConfimPassword.email);
    form_data.append('password', userConfimPassword.password);
    form_data.append('password_confirmation', userConfimPassword.password_confirmation);
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/restPassword`, form_data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
            )
        )
        if (result?.data.status) {
            history('/login');
        } else {
            alert("enter correct password")
        }
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message[0], status: "success" } });

    } catch (err) {
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err.response.data.message, status: "failed" } });
    }
}

const userEditUpdate = function* (data) {
    const { payload } = data;
    var form_data = new FormData();

    for (var key in payload) {
        form_data.append(key, payload[key]);
    }
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/`,
                form_data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
        );
        // console.log('user profile data',result.data)
        yield put({ type: actions.SET_PROFILE_EDIT, payload: result.data })
    } catch (err) {
        yield ({ type: actions.SET_PROFILE_EDIT, payload: [] })
    }
}

const userFlightBookingList = function* () {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user/flight/mybookings`)
        );
        yield put({ type: commonActions.SET_LOADER, payload: false });
        if (result.data.status) {
            yield put({ type: actions.SET_MY_BOOKING, payload: result.data.bookings })
        } else {
            yield ({ type: actions.SET_MY_BOOKING, payload: [] })
        }
    } catch (err) {
        yield ({ type: actions.SET_MY_BOOKING, payload: [] })
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err.message, status: "failed" } });

    }
}

const getMyCompletedBooking = function* () {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user/flight/mycompletedbookings`)
        );
        yield put({ type: commonActions.SET_LOADER, payload: false });
        if (result.data.status) {
            yield put({ type: actions.SET_MY_COMPLETED_BOOKING, payload: result.data.bookings });
        } else {
            yield ({ type: actions.SET_MY_COMPLETED_BOOKING, payload: [] })
        }
    } catch (err) {
        yield ({ type: actions.SET_MY_COMPLETED_BOOKING, payload: [] })
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err.message, status: "failed" } });

    }
}

// const ticketCancel = function* (data) {
//     const { payload } = data;
//     console.log('payload',payload)
//     try {
//         const result = yield call(() =>
//             axios.post(`${API_URL}/user/flight/requestcancellation`, payload)
//         );
//         yield put({ type: commonActions.SET_LOADER, payload: false });
//         console.log('resultdata',result.data)
//         if (result.data.status) {
//             yield put({ type: actions.SET_MY_BOOKING_CANCEL_VERIFY, payload: result.data.bookings })
//             yield put({ type: userActions.GET_MY_BOOKING_CANCEL_LIST, payload: '' })
//             yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "success" } });
//         } else {
//             yield ({ type: actions.SET_MY_BOOKING_CANCEL_VERIFY, payload: [] });
//         }
//     } catch (err) {
//         yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err.message, status: "failed" } });
//         yield ({ type: actions.SET_MY_BOOKING_CANCEL_VERIFY, payload: [] })
//         yield put({ type: commonActions.SET_LOADER, payload: false });
//     }
// }


const ticketCancel = function* (data) {
    const { payload } = data;
    var form_data = new FormData();
    for (var key in payload) {
        form_data.append(key, payload[key]);
    }
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/user/flight/requestcancellation`,
                form_data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
        );
        console.log('result,,..',result?.data)
        if (result.data.status) {
            yield put({ type: actions.SET_MY_BOOKING_CANCEL_VERIFY, payload: result.data.bookings })
            yield put({ type: userActions.GET_MY_BOOKING_CANCEL_LIST, payload: '' })
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "success" } });
        } else {
            yield ({ type: actions.SET_MY_BOOKING_CANCEL_VERIFY, payload: [] });
        }
    } catch (err) {
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err.message, status: "failed" } });
        yield ({ type: actions.SET_MY_BOOKING_CANCEL_VERIFY, payload: [] })
        yield put({ type: commonActions.SET_LOADER, payload: false });
    }
}






const ticketCancelConfirm = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/user/flight/cancelbooking`, payload)
        );
        yield put({ type: actions.SET_MY_BOOKING_CANCEL_CONFIRM, payload: result.data.bookings })
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({ type: actions.GET_MY_BOOKING, payload: [] })
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "success" } });
    } catch (err) {
        yield ({ type: actions.SET_MY_BOOKING_CANCEL_CONFIRM, payload: [] })
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err.message, status: "failed" } });
    }
}

const bookingCancelList = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user/flight/mybookings_cancel`, payload)
        );
        if (result.data.status) {
            yield put({ type: actions.SET_MY_BOOKING_CANCEL_LIST, payload: result.data.bookings });
        } else {
            yield ({ type: actions.SET_MY_BOOKING_CANCEL_LIST, payload: [] });
        }
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield ({ type: actions.SET_MY_BOOKING_CANCEL_LIST, payload: [] })
        yield put({ type: commonActions.SET_LOADER, payload: false });
    }
}

const bookingTicketDetails = function* (data) {
    const { payload } = data;
    var form_data = new FormData();

    for (var key in payload) {
        form_data.append(key, payload[key]);
    }
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/trip_details`,
                form_data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
        );
        if (result.data.status) {
            yield put({ type: actions.SET_MY_BOOKING_TICKET_DETAILS, payload: result.data.message.TripDetailsResponse.TripDetailsResult.TravelItinerary.ItineraryInfo.ReservationItems })
            data.history('/flight-ticket');
        } else {
            yield ({ type: actions.SET_MY_BOOKING_TICKET_DETAILS, payload: [] });
        }
    } catch (err) {
        yield ({ type: actions.SET_MY_BOOKING_TICKET_DETAILS, payload: [] });
    }
}

const getHotelBookings = function* (data) {
    const { payload } = data;
    var form_data = new FormData();

    for (var key in payload) {
        form_data.append(key, payload[key]);
    }
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user/hotel/mybookings`,
                form_data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
        );
        if (result.data.status) {
            yield put({ type: actions.SET_MY_HOTEL_BOOKINGS, payload: result.data.bookings })
        } else {
            yield ({ type: actions.SET_MY_HOTEL_BOOKINGS, payload: [] });
        }
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield ({ type: actions.SET_MY_HOTEL_BOOKINGS, payload: [] })
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Kindly Reload the page', status: "failed" } });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    }
}

const getHotelBookingsCompleted = function* (data) {
    const { payload } = data;
    var form_data = new FormData();

    for (var key in payload) {
        form_data.append(key, payload[key]);
    }
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user/hotel/mycompletedbookings`,
                form_data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
        );
        if (result.data.status) {
            yield put({ type: actions.SET_MY_HOTEL_BOOKINGS_COMPLETED, payload: result.data.bookings });
        } else {
            yield ({ type: actions.SET_MY_HOTEL_BOOKINGS_COMPLETED, payload: [] });
        }
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield ({ type: actions.SET_MY_HOTEL_BOOKINGS_COMPLETED, payload: [] });
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Kindly Reload the page', status: "failed" } });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    }
}

const getHotelBookingsCancel = function* (data) {
    const { payload } = data;
    var form_data = new FormData();

    for (var key in payload) {
        form_data.append(key, payload[key]);
    }
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user/hotel/mybookings_cancel`,
                form_data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
        );
        if (result.data.status) {
            yield put({ type: actions.SET_MY_HOTEL_BOOKINGS_CANCEL, payload: result.data.bookings });
        } else {
            yield ({ type: actions.SET_MY_HOTEL_BOOKINGS_CANCEL, payload: [] });
        }
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield ({ type: actions.SET_MY_HOTEL_BOOKINGS_CANCEL, payload: [] })
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Kindly Reload the page', status: "failed" } });
        yield put({ type: commonActions.SET_LOADER, payload: false });
    }
}
const getHotelBookingsCancelRequest = function* (data) {
    const { payload } = data;
    var form_data = new FormData();

    for (var key in payload) {
        form_data.append(key, payload[key]);
    }
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/user/hotel/requestcancellation`,
                form_data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
        );
        yield put({ type: commonActions.SET_LOADER, payload: false });
        if (result.data.status) {
            yield put({ type: actions.SET_HOTEL_BOOKINGS_CANCEL_REQUEST, payload: result.data.message })
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "success" } });
        } else if (result.data.status === false) {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message.errors, status: "failed" } });
        } else {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message.errors.map((val, index,) => <div key={index}>{val.errorMessage}</div>), status: "failed" } });
        }

    } catch (err) {
        yield ({ type: actions.SET_HOTEL_BOOKINGS_CANCEL_REQUEST, payload: [] })
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err.message, status: "failed" } });
    }
}
const getHotelBookingsCancelVerify = function* (data) {
    const { payload } = data;
    // var form_data = new FormData();
    // for (var key in payload) {
    //     form_data.append(key, payload[key]);
    // }
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/user/hotel/cancelbooking`,
                payload, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
        );
        yield put({ type: commonActions.SET_LOADER, payload: false });
        if (result.data.status === true) {
            yield put({ type: actions.SET_HOTEL_BOOKINGS_CANCEL_VERIFY, payload: result.data.log })
            yield put({ type: userActions.GET_MY_HOTEL_BOOKINGS, payload: '' });
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.log.message && result.data.message, status: "success" } });
        } else if (result.data.status === false) {
            yield ({ type: actions.SET_HOTEL_BOOKINGS_CANCEL_VERIFY, payload: [] });
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "failed" } });
        } else {
            yield ({ type: actions.SET_HOTEL_BOOKINGS_CANCEL_VERIFY, payload: [] });
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message.errors.map((val, index,) => <div key={index}>{val.errorMessage}</div>), status: "failed" } });
        }
    } catch (err) {
        yield ({ type: actions.SET_HOTEL_BOOKINGS_CANCEL_VERIFY, payload: [] })
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err.message, status: "failed" } });

    }
}

const getHotelGuestBookingsCancel = function* (data) {
    const { payload } = data;
    var form_data = new FormData();
    for (var key in payload) {
        form_data.append(key, payload[key]);
    }
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/guest/hotel/requestcancellation`,
                form_data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
        );
        yield put({ type: commonActions.SET_LOADER, payload: false });
        if (result.data.status === true) {
            yield put({ type: actions.SET_HOTEL_BOOKINGS_GUEST_CANCEL_REG, payload: result.data.log })
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "success" } });
            data.history('/hotel-guest-otp')
        } else if (result.data.status === false) {
            yield ({ type: actions.SET_HOTEL_BOOKINGS_GUEST_CANCEL_REG, payload: [] })
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "failed" } });
        } else {
            yield ({ type: actions.SET_HOTEL_BOOKINGS_GUEST_CANCEL_REG, payload: [] })
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message.errors.map((val, index,) => <div key={index}>{val.errorMessage}</div>), status: "failed" } });
        }
    } catch (err) {
        yield ({ type: actions.SET_HOTEL_BOOKINGS_GUEST_CANCEL_REG, payload: [] })
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err.message, status: "failed" } });

    }
}
const getHotelGuestBookingsCancelOtp = function* (data) {
    const { payload } = data;
    // var form_data = new FormData();

    // for (var key in payload) {
    //     form_data.append(key, payload[key]);
    // }
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/guest/hotel/cancelbooking`,
                payload, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
        );
        yield put({ type: commonActions.SET_LOADER, payload: false });
        if (result.data.status === true) {
            yield put({ type: actions.SET_HOTEL_BOOKINGS_GUEST_CANCEL_OTP_VER, payload: result.data.log })
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "success" } });
            data.history('/hotel-guest-cancel')
        } else if (result.data.status === false) {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "failed" } });
            yield ({ type: actions.SET_HOTEL_BOOKINGS_GUEST_CANCEL_OTP_VER, payload: [] });
        } else {
            yield ({ type: actions.SET_HOTEL_BOOKINGS_GUEST_CANCEL_OTP_VER, payload: [] });
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message.errors.map((val, index,) => <div key={index}>{val.errorMessage}</div>), status: "failed" } });
        }
    } catch (err) {
        yield ({ type: actions.SET_HOTEL_BOOKINGS_GUEST_CANCEL_OTP_VER, payload: [] })
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err.message, status: "failed" } });

    }
}
const getUpcomingFlightDetail = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user/flight/mybookings/detail/${payload}`, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
        );
        if (result.data.status) {
            yield put({ type: actions.SET_MY_FLIGHT_UP_BOOKING_DETAILS, payload: result.data.bookings });
            data.history('/flight-ticket')
        } else {
            yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: result.data.message, status: "success" } });
        }
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield ({ type: actions.SET_MY_FLIGHT_UP_BOOKING_DETAILS, payload: null })
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err.message, status: "failed" } });

    }
}
const getCancelFlightDetail = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user/flight/mybookings_cancel/detail/${payload}`, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
        );
        if (result.data.status) {
            yield put({ type: actions.SET_MY_FLIGHT_UP_BOOKING_DETAILS, payload: result.data.bookings[0] });
            data.history('/flight-ticket');
        } else {
            yield ({ type: actions.SET_MY_FLIGHT_UP_BOOKING_DETAILS, payload: null });
        }
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield ({ type: actions.SET_MY_FLIGHT_UP_BOOKING_DETAILS, payload: null });
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err.message, status: "failed" } });

    }
}
const getCompletedFlightDetail = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/user/flight/mycompletedbookings/detail/${payload}`, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
        );
        if (result.data.status) {
            yield put({ type: actions.SET_MY_FLIGHT_UP_BOOKING_DETAILS, payload: result.data.bookings[0] });
            data.history('/flight-ticket');
        } else {
            yield ({ type: actions.SET_MY_FLIGHT_UP_BOOKING_DETAILS, payload: [] });
        }
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield ({ type: actions.SET_MY_FLIGHT_UP_BOOKING_DETAILS, payload: [] })
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err.message, status: "failed" } });

    }
}

const getMyHotelBookingDetails = function* (data) {
    const { payload } = data;
    // var form_data = new FormData();

    // for (var key in payload) {
    //     form_data.append(key, payload[key]);
    // }
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/hotelbooking_details`,
                payload, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
        );
        console.log('result.data',result.data)
        localStorage.setItem('items', JSON.stringify(result.data.message))
        if (result.data.status) {
            yield put({ type: actions.SET_MY_HOTEL_BOOKING_DETAILS, payload: result.data.message });
        } else {
            yield ({ type: actions.SET_MY_HOTEL_BOOKING_DETAILS, payload: [] });
        }
        yield put({ type: commonActions.SET_LOADER, payload: false });
        data.history('/hotel-booking-detail')
    } catch (err) {
        yield ({ type: actions.SET_MY_HOTEL_BOOKING_DETAILS, payload: [] })
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err.message, status: "failed" } });

    }
}

const getMyHotelCancelBookingDetails = function* (data) {
    const { payload } = data;
    // var form_data = new FormData();

    // for (var key in payload) {
    //     form_data.append(key, payload[key]);
    // }
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/hotelbooking_details`,
                payload, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
        );
        if (result.data.status) {
            yield put({ type: actions.SET_MY_HOTEL_BOOKING_DETAILS, payload: result.data.message });
            data.history('/hotel-booking-detail');
        } else {
            yield ({ type: actions.SET_MY_HOTEL_BOOKING_DETAILS, payload: [] });
        }
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield ({ type: actions.SET_MY_HOTEL_BOOKING_DETAILS, payload: [] });
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err.message, status: "failed" } });

    }
}

const getMyHotelCompletedBookingDetails = function* (data) {
    const { payload } = data;
    // var form_data = new FormData();

    // for (var key in payload) {
    //     form_data.append(key, payload[key]);
    // }
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/hotelbooking_details`,
                payload, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
        );
        if (result.data.status) {
            yield put({ type: actions.SET_MY_HOTEL_BOOKING_DETAILS, payload: result.data.message });
            data.history('/hotel-booking-detail');
        } else {
            yield ({ type: actions.SET_MY_HOTEL_BOOKING_DETAILS, payload: [] });
        }
        yield put({ type: commonActions.SET_LOADER, payload: false });
    } catch (err) {
        yield ({ type: actions.SET_MY_HOTEL_BOOKING_DETAILS, payload: [] });
        yield put({ type: commonActions.SET_LOADER, payload: false });
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err.message, status: "failed" } });

    }
}

const getUserPageSlider = function* (data) {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/loginslider`)
        );
        if (result.data.status) {
            yield put({ type: actions.SET_USER_PAGE_SLIDER, payload: result.data.slider });
        } else {
            yield ({ type: actions.SET_USER_PAGE_SLIDER, payload: [] })
        }
    } catch (err) {
        yield ({ type: actions.SET_USER_PAGE_SLIDER, payload: [] })
        yield put({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: err.message, status: "failed" } });

    }
}

export default UserSaga;