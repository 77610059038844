import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import commonActions from "../../redux/common/actions";
import userActions from "../../redux/user/actions";
import { API_URL } from "../../utils/constant";
import setAuthToken from "../../utils/setAuthToken";
import LoaderImage from '../../asset/loader.gif'

const Google = () => {

    const history = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        console.log('API_URL',API_URL)
        axios.get(`${API_URL}/auth/google/callback${window.location.search}`,
        ).then(result => {
            setAuthToken(result.data.token)
            localStorage.setItem('tickatrip-token', result.data.token);
            localStorage.setItem('email', result.data.user.email);
            localStorage.setItem('phone', result.data.user.phone);
            localStorage.setItem('username', result.data.user.username);
            dispatch({ type: userActions.SET_AUTHETICATION, payload: true });
            dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: "Login successfully", status: "success" } });
            history('/');
        }).catch((error) => {
            history('/login');
            dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: "Login failed", status: "failed" } });
        });
    }, [])

    return (
        <div>
            <div className="site_loader">
                <div className="site_loader_img">
                    <img src={LoaderImage} alt="images" />
                </div>
            </div>
        </div>
    );
}

export default Google;

