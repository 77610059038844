import * as React from 'react';
import { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormControl, FormControlLabel, FormGroup, Radio, RadioGroup } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Container } from '@mui/material';
import { debounce } from "@material-ui/core";
import { useDispatch, useSelector } from 'react-redux';
import flightActions from '../../redux/flight/actions';
import axios from 'axios';
import { API_URL } from '../../utils/constant';
import commonActions from '../../redux/common/actions';
import Close from '@mui/icons-material/Close';
import Tune from '@mui/icons-material/Tune';
import { Modal } from 'react-bootstrap';

// import commonActions from '../../redux/common/actions';

// const marks = [
//   {
//     value: 500,
//     label: '500',
//   },
//   {
//     value: 100000,
//     label: '100000',
//   },
// ];

function FlightFilter(props) {
  const dispatch = useDispatch();
  const { filterData, flightList, flightSessionId, flightFilterResult, resultData, flightNameFilter } = useSelector(state => state.flightReducer);
  const [show, setShow] = useState(false);
  var [flightCabin, setFlightCabin] = useState('');
  var [minMaxSlider, setMinMaxSlider] = useState({ MinItem: '', MaxItem: '' })
  const [mobileFilter, setMobileFilter] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    axios.post(
      `${API_URL}/filter`, { 'filter_type': 'flight' }, {
      headers: {
        accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    }
    ).then((res) => {
      console.log('res', res.data)
      setMinMaxSlider(minMaxSlider = { MinItem: res.data.filter.min, MaxItem: res.data.filter.max })
      console.log('minMaxSlider', minMaxSlider)
    }).catch(err => {
      setMinMaxSlider({ MinItem: '', MaxItem: '' })
    })

  }, []);
  // console.log(filterData)
  const flightDetails = (name, status) => {

    let temp = filterData.flightComp;
    if (status) {
      temp = [...temp, name];
    } else {
      temp = filterData.flightComp.filter(el => el !== name);
    }
    let secondTemp = [], temp4 = [];
    let fTemp = temp.map(el => el.toUpperCase());
    flightList.forEach(el => {
      if (fTemp.includes('ALL')) {
        temp4.push(el);
      } else if (el.find(a => (fTemp.includes(a.flightName.toUpperCase())))) {
        temp4.push(el);
      }
    });
    let temp3 = filterData.flightStop.map(el => el.toUpperCase());
    temp4.forEach(el => {
      if ((temp3.includes("NONSTOP") && el.find(val => val.flight_details.every(y => y.totalStops === 0)))) {
        secondTemp.push(el);
      } else if ((temp3.includes("ONESTOP") && el.find(val => val.flight_details.every(y => y.totalStops === 1)))) {
        secondTemp.push(el);
      } else if ((temp3.includes("TWOSTOP") && el.find(val => val.flight_details.every(y => y.totalStops === 2)))) {
        secondTemp.push(el);
      } else if (temp3.includes("ANY")) {
        secondTemp.push(el);
      }
    });
    // dispatch({ type: commonActions.SET_LOADER, payload: true });
    dispatch({ type: flightActions.SET_RESULT_FLIGHT, payload: secondTemp });
    dispatch({ type: flightActions.SET_FILTER_FLIGHT, payload: { ...filterData, flightComp: temp } });
  };

  const flightStopDetails = (name, e) => {
    let temp2 = filterData.flightStop;
    if (e.target.checked) {
      e.target.checked = true;
      temp2 = [...temp2, name];
    } else {
      e.target.checked = false;
      temp2 = temp2.filter(el => el !== name);
    }

    let temp1 = [], secondTemp = [];
    let fTemp = filterData.flightComp.map(el => el.toUpperCase());
    flightList.forEach(el => {
      if (el.find(a => (fTemp.includes(a.flightName.toUpperCase())))) {
        temp1.push(el);
      } else if (fTemp.includes('ALL')) {
        temp1.push(el);
      }
    });
    let temp3 = temp2.map(el => el.toUpperCase());
    temp1.forEach(el => {
      if ((temp3.includes("NONSTOP") && el.find(a => a.flight_details.every(val => val.totalStops === 0)))) {
        secondTemp.push(el);
      } else if ((temp3.includes("ONESTOP") && el.find(a => a.flight_details.every(val => val.totalStops === 1)))) {
        secondTemp.push(el);
      } else if ((temp3.includes("TWOSTOP") && el.find(a => a.flight_details.every(val => val.totalStops === 2)))) {
        secondTemp.push(el);
      } else if (temp3.includes("ANY")) {
        secondTemp.push(el);
      }
    });
    // dispatch({ type: commonActions.SET_LOADER, payload: true });
    dispatch({ type: flightActions.SET_RESULT_FLIGHT, payload: secondTemp });
    dispatch({ type: flightActions.SET_FILTER_FLIGHT, payload: { ...filterData, flightStop: temp2 } });
  };

  const flightCabinDetails = (e) => {
    let temp = e.target.value;
    setFlightCabin(e.target.value);
    props.handleCabinChange(temp);
  };


  const flightPriceDetails = (e, type) => {
    if (type === "price") {
      dispatch({
        type: flightActions.SET_FLIGHT_FILTER, payload: {
          // sessionId: flightSessionId,
          maxResult: 100000,
          filters: {
            ...flightFilterResult?.filters,
            price: {
              min: e[0],
              max: e[1]
            }
          }
        }
      });
      // dispatch({
      //   type: flightActions.GET_FLIGHT_FILTER, payload: {
      //     // sessionId: flightSessionId,
      //     maxResult: 100000,
      //     filters: {
      //       ...flightFilterResult?.filters,
      //       price: {
      //         min: e[0],
      //         max: e[1]
      //       }
      //     }
      //   }
      // })
    }


    let temp = [], secondTemp = [];
    const startPrice = ((e?.[0])) ? e[0] : filterData.flightPrice[0];
    const endPrice = ((e?.[1])) ? e[1] : filterData.flightPrice[1];

    flightList.forEach(el => {
      if (el.find(a => (a.totalFare >= parseInt(`${startPrice}`) && a.totalFare <= parseInt(`${endPrice}`)))) {
        temp.push(el);
      }
      else if (filterData.flightComp.includes('ALL')) {
        temp.push(el);
      }
    });

    dispatch({ type: flightActions.SET_RESULT_FLIGHT, payload: temp });
    temp.forEach(el => {
      if ((filterData.flightStop.includes("Nonstop") && el.find(a => a.flight_details.every(val => val.totalStops === 0)))) {
        secondTemp.push(el);
      } else if ((filterData.flightStop.includes("OneStop") && el.find(a => a.flight_details.every(val => val.totalStops === 1)))) {
        secondTemp.push(el);
      } else if ((filterData.flightStop.includes("TwoStop") && el.find(a => a.flight_details.every(val => val.totalStops === 2)))) {
        secondTemp.push(el);
      } else if (filterData.flightStop.includes("Any")) {
        secondTemp.push(el);
      }
    });
    dispatch({ type: flightActions.SET_RESULT_FLIGHT, payload: secondTemp });
    if (e?.target?.value) {
      dispatch({ type: flightActions.SET_FILTER_FLIGHT, payload: { ...filterData, flightPrice: e } });

    }
  }


  const ResetFilter = () => {
    // dispatch({ type: flightActions.SET_FILTER_FLIGHT, payload: { flightStop: ["Any"], flightComp: ["All"], flightPrice: 100000 } });
    flightPriceDetails([500, 20000], "price",)
    // flightStopDetails(filterData.flightStop.includes("Any"))
    // dispatch({ type: flightActions.SET_FILTER_FLIGHT, payload: { ...filterData, flightStop: temp2 } });
  }


  useEffect(() => {
    setFlightCabin(props?.SearchFlight?.class);
  }, [props]);

  return (
    <div>
      <Container>
        {/* <div className='filter_total_price'>
          <Typography className='tab_td'>Price</Typography>
          <Box>
            <Slider
              aria-label="Always visible"
              defaultValue={filterData.flightPrice}
              min={500}
              max={100000}
              onChange={flightPriceDetails}
              step={500}
              marks={marks}
              valueLabelDisplay="auto"
            />
          </Box>
        </div> */}
        <div className='filter_total_price'>
          {/* <Box className="border-top-gray py-3">
            <Typography className='tab_td'>Max Price</Typography>
            <div className='mr-1'>
              <Slider
                aria-label="Always visible"
                min={parseInt(minMaxSlider.MinItem)}
                max={parseInt(minMaxSlider.MaxItem)}
                onChange={flightPriceDetails}
                step={500}
                // marks={marks}ZZZ
                defaultValue={filterData.flightPrice > 500 ? filterData.flightPrice : 500}
                valueLabelDisplay="auto"
              />
            </div>
          </Box> */}
          <div className='dis_none'>
            <Box className="border-top-gray py-3">
              <div className="pb-20 d-flex justify-content-between">
                <span className="refine-results">Refine Results</span>
                <button className="MuiButtonBase-root MuiButton-root MuiButton-text" tabindex="0" type="button" title="Clear Filter">
                  <span className="MuiButton-label">
                    <span className="delete-color" onClick={ResetFilter}>Clear</span>
                  </span>
                  <span className="MuiTouchRipple-root">
                  </span>
                </button>
              </div>
              <Typography className='tab_td'>Price</Typography>
              <div className='mr-1'>
                <Slider
                  aria-label="Always visible"
                  onChange={(e) => (flightPriceDetails(e.target.value, 'price'), 400)}
                  min={parseInt(minMaxSlider.MinItem)}
                  max={parseInt(minMaxSlider.MaxItem)}
                  step={20}
                  value={[flightFilterResult?.filters?.price?.min ? flightFilterResult?.filters?.price?.min : 500, flightFilterResult?.filters?.price?.max ? flightFilterResult?.filters?.price?.max : 20000]}
                  valueLabelDisplay='auto'
                />
                <div className='dis_flex'>
                  <span className='minmax_title'>Min: {parseInt(minMaxSlider.MinItem)}</span>
                  <span className='minmax_title'>Max: {parseInt(minMaxSlider.MaxItem)} </span>
                </div>
              </div>
            </Box>
            <div className='accordian_inner'>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className='tab_td'>Airlines</Typography>

                </AccordionSummary>

                <AccordionDetails>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox defaultChecked={true} />} label="All" value="All" onChange={(e) => flightDetails('All', e.target.checked)} />
                    {/* <FormControlLabel control={<Checkbox />} label="Indigo Airlines" value="Indigo Airlines" onChange={(e) => flightDetails("Indigo Airlines", e.target.checked)} />
                          <FormControlLabel control={<Checkbox />} label="Air India" value="Air India" onChange={(e) => flightDetails("Air India", e.target.checked)} />
                          <FormControlLabel control={<Checkbox />} label="Vistara" value="Vistara" onChange={(e) => flightDetails("Vistara", e.target.checked)} /> */}

                    <div className='flitername_list'>
                      {flightNameFilter.map((val, index) => {
                        return (
                          <FormControlLabel control={<Checkbox />} label={val} value={val} onChange={(e) => flightDetails(val, e.target.checked)} />
                        );
                      })
                      }
                    </div>
                  </FormGroup>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className='tab_td'>Cabin</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      // value={flightCabin || ''}
                      onChange={flightCabinDetails}
                    >
                      <FormControlLabel value="Business" control={<Radio />} label="Business Class" />
                      <FormControlLabel value="Economy" control={<Radio />} label="Economy Class" />
                      <FormControlLabel value="Premium" control={<Radio />} label="Premium Class" />
                    </RadioGroup>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
              <Accordion >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography className='tab_td'>Stops</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox defaultChecked={true} />} value={"Any"} label="Any" onChange={(e) => flightStopDetails('Any', e)} />
                    <FormControlLabel control={<Checkbox />} value={"Nonstop"} label="Non-stop" onChange={(e) => flightStopDetails('Nonstop', e)} />
                    <FormControlLabel control={<Checkbox />} value={"OneStop"} label="1 Stop" onChange={(e) => flightStopDetails('OneStop', e)} />
                    <FormControlLabel control={<Checkbox />} value={"TwoStop"} label="2 Stop" onChange={(e) => flightStopDetails('TwoStop', e)} />
                  </FormGroup>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>

          <div className='mobile_flight_filter'>
            <div className='mobileFilter_open'>
              <h4>Flight List</h4>
              <div className='open_close_icons' onClick={handleShow}>
                <Tune />
              </div>
            </div>
            <Modal
              className='modalOfFlightFilter'
              show={show} onHide={handleClose}
            >
              <div className='mobile_flight_filter_modal'>
                <div className='onclose_btn' onClick={handleClose}>
                  <Close />
                </div>
                <Box className="border-top-gray py-3">
                  <div className="pb-20 d-flex justify-content-between">
                    <span className="refine-results">Refine Results</span>
                    <button className="MuiButtonBase-root MuiButton-root MuiButton-text" tabindex="0" type="button" title="Clear Filter">
                      <span className="MuiButton-label">
                        <span className="delete-color" onClick={ResetFilter}>Clear</span>
                      </span>
                      <span className="MuiTouchRipple-root">
                      </span>
                    </button>
                  </div>
                  <Typography className='tab_td'>Price</Typography>
                  <div className='mr-1'>
                    <Slider
                      aria-label="Always visible"
                      onChange={(e) => (flightPriceDetails(e.target.value, 'price'), 400)}
                      min={parseInt(minMaxSlider.MinItem)}
                      max={parseInt(minMaxSlider.MaxItem)}
                      step={20}
                      // marks={hotelPrice}
                      value={[flightFilterResult?.filters?.price?.min ? flightFilterResult?.filters?.price?.min : 500, flightFilterResult?.filters?.price?.max ? flightFilterResult?.filters?.price?.max : 20000]}
                      valueLabelDisplay='auto'
                    />
                    <div className='dis_flex'>
                      <span className='minmax_title'>Min: {parseInt(minMaxSlider.MinItem)}</span>
                      <span className='minmax_title'>Max: {parseInt(minMaxSlider.MaxItem)} </span>
                    </div>
                  </div>
                </Box>
                <div className='accordian_inner'>
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className='tab_td'>Airlines</Typography>

                    </AccordionSummary>

                    <AccordionDetails>
                      <FormGroup>
                        <FormControlLabel control={<Checkbox defaultChecked={true} />} label="All" value="All" onChange={(e) => flightDetails('All', e.target.checked)} />
                        {/* <FormControlLabel control={<Checkbox />} label="Indigo Airlines" value="Indigo Airlines" onChange={(e) => flightDetails("Indigo Airlines", e.target.checked)} />
                          <FormControlLabel control={<Checkbox />} label="Air India" value="Air India" onChange={(e) => flightDetails("Air India", e.target.checked)} />
                          <FormControlLabel control={<Checkbox />} label="Vistara" value="Vistara" onChange={(e) => flightDetails("Vistara", e.target.checked)} /> */}

                        <div className='flitername_list'>
                          {flightNameFilter.map((val, index) => {
                            return (
                              <FormControlLabel control={<Checkbox />} label={val} value={val} onChange={(e) => flightDetails(val, e.target.checked)} />
                            );
                          })
                          }
                        </div>
                      </FormGroup>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className='tab_td'>Cabin</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          // value={flightCabin || ''}
                          onChange={flightCabinDetails}
                        >
                          <FormControlLabel value="Business" control={<Radio />} label="Business Class" />
                          <FormControlLabel value="Economy" control={<Radio />} label="Economy Class" />
                          <FormControlLabel value="Premium" control={<Radio />} label="Premium Class" />
                        </RadioGroup>
                      </FormControl>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography className='tab_td'>Stops</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <FormGroup>
                        <FormControlLabel control={<Checkbox defaultChecked={true} />} value={"Any"} label="Any" onChange={(e) => flightStopDetails('Any', e)} />
                        <FormControlLabel control={<Checkbox />} value={"Nonstop"} label="Non-stop" onChange={(e) => flightStopDetails('Nonstop', e)} />
                        <FormControlLabel control={<Checkbox />} value={"OneStop"} label="1 Stop" onChange={(e) => flightStopDetails('OneStop', e)} />
                        <FormControlLabel control={<Checkbox />} value={"TwoStop"} label="2 Stop" onChange={(e) => flightStopDetails('TwoStop', e)} />
                      </FormGroup>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>

            </Modal>
          </div>
        </div>
      </Container>

    </div>
  )
}

export default React.memo(FlightFilter);