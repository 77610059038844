import React from 'react';
import { Container } from "@material-ui/core";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GroupIcon from '@mui/icons-material/Group';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import hotelActions from '../../redux/hotel/actions';
import { Table } from 'react-bootstrap';
import { useRef } from 'react';
import commonActions from '../../redux/common/actions';
import travellerActions from '../../redux/traveller/actions';
import loaderGifImg from "../../asset/loader_hotel.gif";
import axios from 'axios';
import { RAZOR_KEY, RAZOR_KEY_SECRET, CURRENCY, API_URL, TIMEOUT } from '../../utils/constant';
import hotelBannerImg from '../../asset/hotel/2.jpg';
import hotelFacilityIcon from '../../asset/icons/facilityIcons.png';
import hotelRoomIcon from '../../asset/icons/hotel_bed_icon.png';
import CalenderBlue from '../../asset/icons/calender_icon.png';
import GuestsBlue from '../../asset/icons/guests_icon.png';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function Item(props) {
    const { sx, ...other } = props;
    return (
        <Box
            {...other}
        />
    );
}

Item.propTypes = {
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
        ),
        PropTypes.func,
        PropTypes.object,
    ]),
};

const validationSchema = Yup.object().shape({
    title: Yup.string()
        .required('title is required'),
    first_name: Yup.string().matches(
        /^[A-Za-z\\s]*$/,
        "first name should not contain spaces or numbers"
    ).required('First Name is required'),
    last_name: Yup.string().matches(
        /^[A-Za-z\\s]*$/,
        "Last name should not contain spaces or numbers"
    ).required('Last name is required'),
    phone: Yup.number()
        .test('len', 'Must be exactly 10 characters', val => val.toString().length === 10)
        .required('Phone no is required').typeError('Phone no is required'),
    email: Yup.string()
        .required('Email is required'),
    acceptTerms: Yup.boolean()
        .required("The terms and conditions must be accepted.")
        .oneOf([true], "The terms and conditions must be accepted.")
});

export const HotelPayment = () => {
    const { loading } = useSelector(
        (state) => state.commonReducer
    )
    const dispatch = useDispatch()
    const history = useNavigate()
    const formSubmit = useRef();
    const { hotelSessionId, tempHotelDetails, hotelCoupons } = useSelector((state) => state.hotelReducer)
    const { hotelSearchCondition } = useSelector((state) => state.hotelReducer);
    const { userProfileData } = useSelector((state) => state.travellerReducer);
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const couponRef = useRef();
    const ratingValue = tempHotelDetails?.val?.hotelRating
    const [bookingAdultCount, setBookingAdultCount] = useState(0)
    const [bookingChildCount, setBookingChildCount] = useState(0)
    const [totelFare, setTotelFare] = useState('')
    const [couponDiscount, setCouponDiscount] = useState()
    const [finalTotal, setFinalTotal] = useState('');
    const [usedCoupons, setUsedCoupons] = useState([]);
    const [checkOutDetails, setCheckOutDetails] = useState([]);

    // console.log('checkOutDetails', checkOutDetails)
    const onSubmit = async (data) => {
        // console.log('tempBooking', data)
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        await axios.get(`${API_URL}/email/${data.email}`).then(el => {
            if (el) {
                // console.log('el',el?.data)
                let tempBooking = hotelSearchCondition?.occupancy

                let tempData = []
                tempBooking.map((g, index) =>
                    tempData.push({
                        room_no: g.room_no,
                        adult: {
                            title: [...Array(g.adult)].map((val) => data.title),
                            firstName: [...Array(g.adult)].map((val) => data.first_name),
                            lastName: [...Array(g.adult)].map((val) => data.last_name),
                        },
                        child: {
                            title: [...Array(g.child)].map((val, index) => `Master`),
                            firstName: [...Array(g.child)].map((val, index) => `child ${index + 1}`),
                            lastName: [...Array(g.child)].map((val, index) => `child ${index + 1}`),
                        }
                    })
                );
                console.log('tempData', tempData)
                console.log('tempData', tempBooking)
                if (tempHotelDetails?.item.netPrice) {
                    let price = parseFloat(finalTotal);
                    let temp1 = JSON.parse(localStorage.getItem('temp1'));
                    // let tempcheckout ={}
                    // temp1['paxDetails'] = tempData;
                    // if (!!couponDiscount) {
                    //     temp1['couponDiscount'] = couponDiscount;
                    // }
                    // temp1['TotalFare'] = (price?.toString().split('').includes('.') ? Math.floor(price?.toString().split('.')[0]) * 100 + parseFloat(price?.toString().split('.')[1]) : parseFloat(price) * 100) / 100;
                    // console.log('email',data.email)
                    let tempcheckout = {
                        // occupancy: JSON.stringify(tempData),
                        checkin: hotelSearchCondition?.checkin,
                        Checkout: hotelSearchCondition?.checkout,
                        // amount: price?.toString().split('').includes('.') ? Math.floor(price?.toString().split('.')[0]) * 1 + parseFloat(price?.toString().split('.')[1]) : parseFloat(price) * 1,
                        amount: parseInt(price),
                        currency: CURRENCY,
                        payment_capture: true,
                        data: {
                            sessionId: hotelSessionId,
                            productId: tempHotelDetails?.item.productId,
                            tokenId: tempHotelDetails?.val.tokenId,
                            rateBasisId: tempHotelDetails?.item.rateBasisId,
                            clientRef: tempHotelDetails?.val.productId,
                            customerEmail: data.email,
                            customerPhone: data.phone,
                            bookingNote: "Remark",
                            paxDetails: tempData,
                        },
                        name: data.first_name + " " + data.last_name,
                        description: 'Tick A Trip Hotel Booking Payment',
                        hotelId: tempHotelDetails?.val.hotelId,
                        customerName: data.first_name + " " + data.last_name,
                        customerPhone: data.phone,
                        customerEmail: data.email,
                        customerGst: data.gstNo,
                        acceptTerms: data.acceptTerms,
                        title: data.title,
                        transactionId: "",
                        paymentStatus: "",
                        hotelName: tempHotelDetails?.val.hotelName,
                        hotelCity: tempHotelDetails?.val.city,
                        hotelCountry: tempHotelDetails?.val.country,
                        hotelAddress: tempHotelDetails?.val.address,
                        couponDiscount: couponDiscount === undefined ? "" : parseInt(couponDiscount.toString()),
                        TotalFare: parseInt(price.toString())
                        // TotalFare: (price?.toString().split('').includes('.') ? Math.floor(price?.toString().split('.')[0]) * 100 + parseFloat(price?.toString().split('.')[1]) : parseFloat(price) * 100) / 100
                    }
                    console.log('room price...', price)
                    // let tempcheckout = {
                    //     // occupancy: JSON.stringify(tempData),
                    //     checkin: hotelSearchCondition?.checkin,
                    //     Checkout: hotelSearchCondition?.checkout,
                    //     amount: price?.toString().split('').includes('.') ? Math.floor(price?.toString().split('.')[0]) * 1 + parseFloat(price?.toString().split('.')[1]) : parseFloat(price) * 1,
                    //     currency: CURRENCY,
                    //     payment_capture: true,
                    //     name: data.first_name + " " + data.last_name,
                    //     description: 'Tick A Trip Hotel Booking Payment',
                    //     sessionId: hotelSessionId,
                    //     productId: tempHotelDetails?.item.productId,
                    //     tokenId: tempHotelDetails?.val.tokenId,
                    //     hotelId: tempHotelDetails?.val.hotelId,
                    //     rateBasisId: tempHotelDetails?.item.rateBasisId,
                    //     clientRef: tempHotelDetails?.val.productId,
                    //     customerName: data.first_name + " " + data.last_name,
                    //     customerEmail: data.email,
                    //     customerPhone: data.phone,
                    //     customerGst: data.gstNo,
                    //     acceptTerms: data.acceptTerms,
                    //     title: data.title,
                    //     transactionId: "",
                    //     paymentStatus: "",
                    //     bookingNote: "Remark",
                    //     paxDetails:  JSON.stringify(tempData),
                    //     hotelName: tempHotelDetails?.val.hotelName,
                    //     hotelCity: tempHotelDetails?.val.city,
                    //     hotelCountry: tempHotelDetails?.val.country,
                    //     hotelAddress: tempHotelDetails?.val.address,
                    //     couponDiscount: couponDiscount === undefined ? "" : couponDiscount,
                    //     TotalFare: (price?.toString().split('').includes('.') ? Math.floor(price?.toString().split('.')[0]) * 100 + parseFloat(price?.toString().split('.')[1]) : parseFloat(price) * 100) / 100
                    // }
                    // setCheckOutDetails(price);
                    dispatch({ type: hotelActions.GET_HOTEL_CHECKOUT, payload: tempcheckout });
                    // var options = {
                    //     key: RAZOR_KEY,
                    //     key_secret: RAZOR_KEY_SECRET,
                    //     amount: price?.toString().split('').includes('.') ? Math.floor(price?.toString().split('.')[0]) * 100 + parseFloat(price?.toString().split('.')[1]) : parseFloat(price) * 100,
                    //     currency: CURRENCY,
                    //     payment_capture: true,
                    //     name: data.first_name + " " + data.last_name,
                    //     description: 'Tick A Trip Hotel Booking Payment',
                    //     timeout: TIMEOUT,
                    //     handler: function (response) {
                    //         if (response.razorpay_payment_id) {
                    //             let temp1 = JSON.parse(localStorage.getItem('temp1'));
                    //             temp1['transactionId'] = response.razorpay_payment_id;
                    //             temp1['paymentStatus'] = response?.razorpay_payment_id ? 'true' : 'false';
                    //             temp1['paxDetails'] = tempData;
                    //             if (!!couponDiscount) {
                    //                 temp1['couponDiscount'] = couponDiscount;
                    //             }
                    //             temp1['TotalFare'] = (price?.toString().split('').includes('.') ? Math.floor(price?.toString().split('.')[0]) * 100 + parseFloat(price?.toString().split('.')[1]) : parseFloat(price) * 100) / 100;
                    //             console.log(response?.razorpay_payment_id);
                    //             dispatch({ type: hotelActions.GET_HOTEL_BOOKING, payload: temp1, history: history });
                    //         }
                    //         localStorage.removeItem('temp1');
                    //     },
                    //     prefill: {
                    //         name: data.first_name + " " + data.last_name,
                    //         email: data.email,
                    //         conatct: data.phone,
                    //     },
                    //     notes: {
                    //         address: ''
                    //     },
                    //     theme: {
                    //         color: '#0543e9'
                    //     }
                    // }
                    // var pay = new window.Razorpay(options);
                    // pay.on('payment.failed', function (response) {
                    // });
                    // localStorage.setItem('temp1', JSON.stringify({
                    //     sessionId: hotelSessionId,
                    //     productId: tempHotelDetails?.item.productId,
                    //     tokenId: tempHotelDetails?.val.tokenId,
                    //     hotelId: tempHotelDetails?.val.hotelId,
                    //     rateBasisId: tempHotelDetails?.item.rateBasisId,
                    //     clientRef: tempHotelDetails?.val.productId,
                    //     customerName: data.first_name + " " + data.last_name,
                    //     customerEmail: data.email,
                    //     customerPhone: data.phone,
                    //     customerGst: data.gstNo,
                    //     transactionId: "",
                    //     paymentStatus: "",
                    //     bookingNote: "Remark",
                    //     paxDetails: [],
                    //     hotelName: tempHotelDetails?.val.hotelName,
                    //     hotelCity: tempHotelDetails?.val.city,
                    //     hotelCountry: tempHotelDetails?.val.country,
                    //     hotelAddress: tempHotelDetails?.val.address,
                    // }));
                    // pay.open();
                } else {
                    alert('enter the amount')
                }
            } else {
                dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: "Email Address Invalid", status: "failed" } });
            }
            dispatch({ type: commonActions.SET_LOADER, payload: false });
            // window.location.assign('https://tickatrip.travel/server/api/checkout/');
        }).catch(el => {
            console.log('el...', el)
            dispatch({ type: commonActions.SET_LOADER, payload: false });
            dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: "test", status: "failed" } });
        })
    }

    const handleRefSubmit = () => {

        if (typeof formSubmit.current.requestSubmit === 'function') {
            formSubmit.current.requestSubmit();
            // const formdata={
            //     ...checkOutDetails
            // }
            // // console.log('checkOutDetails',formdata)
            // if(formdata){
            //     const data=JSON.stringify(formdata)
            //     console.log('form',formdata)
            //     window.location.assign(`https://tickatrip.travel/server/api/checkout/${data}`);
            // }


        } else {
            formSubmit.current.dispatchEvent(
                new Event('submit', { cancelable: true })
            );
        }
    }

    const applyCoupon = async (el) => {

        dispatch({ type: commonActions.SET_LOADER, payload: true });
        await axios.get(`${API_URL}/hotel-coupon/${el}`).then(el => {

            if (el.data.status === true) {
                let tempCouponDis = parseFloat(el.data.message.coupon.coupon_discount);
                let tempCalc = parseFloat((totelFare - (totelFare * parseFloat((1 - parseFloat((tempCouponDis / 100).toFixed(2)))).toFixed(2))).toFixed(2));
                setCouponDiscount(tempCalc);

                if (parseInt(finalTotal) <= parseInt(tempCalc)) {
                    setCouponDiscount('')
                    dispatch({ type: commonActions.SET_LOADER, payload: false });
                    dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Coupon not found', status: "failed" } });
                } else {
                    setFinalTotal(parseFloat((totelFare - tempCalc).toFixed(2)));
                    setUsedCoupons([el.data.message.coupon])
                    dispatch({ type: commonActions.SET_LOADER, payload: false });
                    dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Coupon code copy successfully', status: "true" } });
                }

            } else {
                // setCouponDiscount('')
                dispatch({ type: commonActions.SET_LOADER, payload: false });
                // dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Coupon not found', status: "failed" } });
            }
        }).catch(el => {
            setCouponDiscount('')
            dispatch({ type: commonActions.SET_LOADER, payload: false });
            dispatch({ type: commonActions.SET_COMMON_TOASTER_ALERT, payload: { open: true, message: 'Coupon request failed', status: "failed" } });
        });
    }

    const handleRemove = (code) => {
        setUsedCoupons([]);
        setCouponDiscount(0);
        setFinalTotal(totelFare);
    }

    if (!tempHotelDetails?.item || !tempHotelDetails?.val) {
        history(-1);
    }

    useEffect(() => {
        if (hotelSearchCondition) {
            let tempAdult = hotelSearchCondition.occupancy
            if (tempAdult) {
                setBookingAdultCount(tempAdult?.map((val) => val.adult).reduce((total, val) => { return total + val }))
                setBookingChildCount(tempAdult?.map((val) => val.child).reduce((total, val) => { return total + val }))
            }
        }
    }, [hotelSearchCondition]);

    useEffect(() => {
        return () => {
            dispatch({ type: hotelActions.SET_COUPONS, payload: [] });
            localStorage.removeItem('temp1');
        }
    }, [tempHotelDetails?.item]);

    useEffect(() => {
        // if (localStorage.getItem('tickatrip-token')) {
        //     dispatch({ type: commonActions.SET_LOADER, payload: true });
        //     dispatch({ type: hotelActions.GET_COUPONS });
        //     dispatch({ type: travellerActions.GET_PROFILE_DATA });
        // }
        dispatch({ type: commonActions.SET_LOADER, payload: true });
        dispatch({ type: hotelActions.GET_COUPONS });
        dispatch({ type: travellerActions.GET_PROFILE_DATA });
    }, []);

    useEffect(() => {
        if (userProfileData) {
            reset({
                title: userProfileData?.title,
                first_name: userProfileData?.first_name,
                last_name: userProfileData?.last_name,
                email: userProfileData?.email,
                phone: userProfileData?.phone,
            })
        }
    }, [userProfileData]);

    useEffect(() => {
        if (tempHotelDetails) {
            setTotelFare(parseFloat(tempHotelDetails.item.netPrice))
            setFinalTotal(parseFloat(tempHotelDetails.item.netPrice))
        }
    }, [])
    console.log('totelFare', totelFare)
    return (
        <div className='popular_detail_sec'>
            {loading ?
                <div>
                    <div className='blur_bg'></div>
                    <div className='loaderGif'>
                        <img src={loaderGifImg} alt='' />
                    </div>
                </div>
                :
                null
            }
            <div className='hotelBanner' style={{ backgroundImage: `url(${hotelBannerImg})` }}>
                <h1 className="text-white">Booking</h1>
            </div>
            <div className='popular_search hotelPayment_searchVal'>
                <Container>
                    <div className='hotel_search_payment_chk'>
                        <div className='room_details_grid'>
                            <h6>Check - In</h6>
                            <div>
                                <img src={CalenderBlue} alt='calender icon' className='calender_icon' /><span>{hotelSearchCondition.checkin}</span>
                            </div>

                        </div>
                        <div className='room_details_grid'>
                            <h6>Check - Out</h6>
                            <div>
                                <img src={CalenderBlue} alt='calender icon' className='calender_icon' /><span>{hotelSearchCondition.checkout}</span>
                            </div>
                        </div>
                        <div className='room_details_grid'>
                            <h6>Rooms</h6>
                            <div>
                                <img src={hotelRoomIcon} alt='location icons' className='guests_icon' /><span>{hotelSearchCondition.occupancy.length} Rooms</span>
                            </div>
                        </div>
                        <div className='room_details_grid'>
                            <h6>Guests</h6>
                            <div>
                                <img src={GuestsBlue} alt='location icons' className='guests_icon' /><span>{bookingAdultCount && bookingChildCount ? (bookingAdultCount + bookingChildCount) : (bookingAdultCount || bookingChildCount)}  Guests</span>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <div className='popular_detail_con hotel_list'>
                <Box sx={{ flexGrow: 1 }}>
                    <Container>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-6'>
                                        <div className='hotel_payment_head'>
                                            <div className='hotel_payment_head hotelName'>
                                                <h5 className="MuiTypography-root MuiTypography-h5">
                                                    {tempHotelDetails?.val.hotelName}
                                                </h5>
                                                <span className="MuiTypography-root MuiTypography-span pay_login">
                                                    {tempHotelDetails?.item.description}
                                                </span>
                                                <Rating
                                                    name="hover-feedback"
                                                    value={ratingValue}
                                                    readOnly
                                                    precision={0.2}
                                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    {/* <h5 className="MuiTypography-root MuiTypography-h5">
                                        {tempHotelDetails?.val.hotelName}
                                    </h5>
                                    <span className="MuiTypography-root MuiTypography-span pay_login">
                                        {tempHotelDetails?.item.description}
                                    </span>
                                    <Rating
                                        name="hover-feedback"
                                        value={ratingValue}
                                        readOnly
                                        precision={0.2}
                                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                    /> */}
                                    <div className='hotel_fecility_sec'>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-6'>
                                                <Box className='payment_form_head'>
                                                    <h6 className="MuiTypography-root MuiTypography-h6">
                                                        Refund and Cancellation Policy
                                                    </h6>
                                                </Box>
                                                <ul>
                                                    <li>{tempHotelDetails?.item.fareType}</li>
                                                    <li>{tempHotelDetails?.item.cancellationPolicy} * The cancellation policy is setup by hotels, not by tickatrip *</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='hotel_payment'>
                                    <Container>
                                        <Box sx={style}>
                                            <div className='confirm_ticket_pop'>
                                                <Box sx={{ flexGrow: 1 }} className="flight_pop_contentent paddTop-0">
                                                    <Grid className='row'>
                                                        <Grid className='col-lg-7 col-md-7 col-sm-6 col-12'>

                                                            <div className="hotel_payment_address">
                                                                <Box sx={{ flexGrow: 1 }}>
                                                                    <Grid container spacing={2}>
                                                                        <Grid item xs={12}>
                                                                            <div className='row'>
                                                                                <div className="addGuest col-lg-6 col-md-6">
                                                                                </div>
                                                                                <div className='col-lg-12 guest_details_table'>
                                                                                    {hotelSearchCondition?.occupancy?.map((g, i) => (
                                                                                        <div key={'hotelSearchCondition' + i}>
                                                                                            <Box className='payment_form_head'>
                                                                                                <h6 className="MuiTypography-root MuiTypography-h6">
                                                                                                    Room : {g.room_no}
                                                                                                </h6>
                                                                                            </Box>
                                                                                            <Table striped bordered hover>
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>Room No</th>
                                                                                                        <th>Adult Count</th>
                                                                                                        <th>Child Count</th>
                                                                                                        <th>Total Guest</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td>{g.room_no}</td>
                                                                                                        <td>{g.adult}</td>
                                                                                                        <td>{g.child ? g.child : '0'}</td>
                                                                                                        <td>{g.adult && g.child ? g.adult + g.child : g.adult || g.child}</td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </Table>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <div className='flight_pop_accourdian'>
                                                                                <div className="MuiTypography-root MuiTypography-div">
                                                                                    <Box className='payment_form_head '>
                                                                                        <h6 className="MuiTypography-root MuiTypography-h6 infor_title">
                                                                                            Add Your Informations:
                                                                                        </h6>
                                                                                    </Box>
                                                                                    <div className='privay_check_box'>
                                                                                        <Grid item xs={12}>
                                                                                            <form className='payment_form_details row' onSubmit={handleSubmit(onSubmit)} ref={formSubmit}>
                                                                                                <Grid className='col-lg-2 col-md-2'>
                                                                                                    <div className="form-group">
                                                                                                        <label>Title</label>
                                                                                                        <select  {...register('title')} className={`form-control ${errors.title ? 'is-invalid' : ''}`} aria-label="Default select example" >
                                                                                                            <option value="">Title</option>
                                                                                                            <option value="Mr">Mr</option>
                                                                                                            <option value="Miss">Miss</option>
                                                                                                            <option value="Mrs">Mrs</option>
                                                                                                            <option value="Lord">Lord</option>
                                                                                                            <option value="Lady">Lady</option>
                                                                                                            <option value="Inf">Inf</option>
                                                                                                        </select>
                                                                                                        <div className="invalid-feedback">{errors.title?.message}</div>
                                                                                                    </div>
                                                                                                </Grid>
                                                                                                <Grid className='col-lg-5  col-md-5'>
                                                                                                    <div className="form-group">
                                                                                                        <label>First Name</label>
                                                                                                        <input name="first_name" type="text" id="hotel-name" {...register('first_name')} className={`form-control ${errors.first_name ? 'is-invalid' : ''}`} />
                                                                                                        <div className="invalid-feedback">{errors.first_name?.message}</div>
                                                                                                    </div>
                                                                                                </Grid>
                                                                                                <Grid className='col-lg-5  col-md-5'>
                                                                                                    <div className="form-group">
                                                                                                        <label>Last Name</label>
                                                                                                        <input name="last_name" type="text" id="hotel-name" {...register('last_name')} className={`form-control ${errors.last_name ? 'is-invalid' : ''}`} />
                                                                                                        <div className="invalid-feedback">{errors.last_name?.message}</div>
                                                                                                    </div>
                                                                                                </Grid>
                                                                                                <Grid className='col-lg-6  col-md-6'>
                                                                                                    <div className="form-group col">
                                                                                                        <label>Email</label>
                                                                                                        <input name="email" type="text" {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                                                                                                        <div className="invalid-feedback">{errors.email?.message}</div>
                                                                                                    </div>
                                                                                                </Grid>
                                                                                                <Grid className='col-lg-6  col-md-6'>
                                                                                                    <div className="form-group col">
                                                                                                        <label>Phone</label>
                                                                                                        <input
                                                                                                            onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                                                                                            name="phone"
                                                                                                            type="number" {...register('phone')}
                                                                                                            className={`form-control ${errors.phone ? 'is-invalid' : ''}`} />
                                                                                                        <div className="invalid-feedback">{errors.phone?.message}</div>
                                                                                                    </div>
                                                                                                </Grid>
                                                                                                <Grid className='col-lg-6 col-md-6'>
                                                                                                    <div className="form-group col">
                                                                                                        <label>GST No (optional)</label>
                                                                                                        <input name="gstNo" type="number" {...register('gstNo')} className={`form-control ${errors.gstNo ? 'is-invalid' : ''}`} />
                                                                                                    </div>
                                                                                                </Grid>
                                                                                                <div className="form-group form-check col-lg-12 col-md-12 col-sm-12 col-12 hotel_payment_check">
                                                                                                    <input name="acceptTerms" type="checkbox" onKeyUp={(e) => (e.which === 13)
                                                                                                        && (e.target.checked = !e.target.checked)
                                                                                                    }
                                                                                                        {...register('acceptTerms')} id="acceptTerms" className={`form-check-input ${errors.acceptTerms ? 'is-invalid' : ''}`} />
                                                                                                    <label htmlFor="acceptTerms" className="form-check-label offer_check">
                                                                                                        I agree to the terms and conditions and privacy policy
                                                                                                    </label>
                                                                                                    <div className="invalid-feedback">{errors.acceptTerms?.message}</div>
                                                                                                </div>
                                                                                                <div className='pament_btn_sec col-lg-12  col-md-12'>
                                                                                                    <Button
                                                                                                        onClick={handleSubmit(onSubmit)}
                                                                                                        className='book_tick pop_con'>CONFIRM & BOOK</Button>
                                                                                                    {/* <a onClick={handleRefSubmit} href={`https://tickatrip.travel/server/api/checkout/data=oooo`}>confrim Booking</a> */}
                                                                                                </div>
                                                                                            </form>
                                                                                        </Grid>
                                                                                        {!localStorage.getItem('tickatrip-token') &&
                                                                                            <span className="MuiTypography-root pay_login">
                                                                                                You are booking as a guest, <Link to='/'>Login</Link> or <Link to='/'>Login</Link> if you want to save your reservaion on your account.
                                                                                            </span>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            </div>

                                                        </Grid>
                                                        <Grid className='col-lg-5 col-md-5 col-sm-6 col-12'>
                                                            <div className='flight_pop_total'>
                                                                <Box
                                                                    component="form"
                                                                    sx={{
                                                                        '& > :not(style)': { width: '100%' },
                                                                    }}
                                                                    noValidate
                                                                    autoComplete="off"
                                                                >

                                                                    {hotelCoupons?.filter(obj => usedCoupons.length === 0 || usedCoupons.find(el => el.coupon_code !== obj.coupon_code)).map(el => (
                                                                        el.coupon_code !== couponRef?.current?.value &&
                                                                        <div class="coupon-card">
                                                                            <h3>{el.coupon_description}</h3>
                                                                            <div class="coupon-row" onClick={() => applyCoupon(el.coupon_code)}>
                                                                                <span id="cpnCode">{el.coupon_code}</span>
                                                                                <span id="cpnBtn" className='couonCodeCopy'>Apply</span>
                                                                            </div>
                                                                            <p>Valid Till : {el.coupon_valid_upto}</p>
                                                                            <div class="circle1"></div>
                                                                            <div class="circle2"></div>
                                                                        </div>
                                                                    ))}
                                                                    {/* {localStorage.getItem('tickatrip-token') && */}
                                                                    <>
                                                                        <TextField id="outlined-basic" inputRef={couponRef} placeholder="Add Coupon code" variant="outlined" />
                                                                        <Button className='coupon_apply book_tick' onClick={() => applyCoupon(couponRef.current.value)}>Apply Coupon</Button>
                                                                    </>

                                                                    {usedCoupons.map(el => {
                                                                        return (
                                                                            <div className='apply_coupon_remove'>
                                                                                <p>{el.coupon_code}</p>
                                                                                <button onClick={() => handleRemove(el.coupon_code)}>Remove</button>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </Box>
                                                                <div className='pop_total_card'>
                                                                    <div className='pop_total_card_single nd-head'>
                                                                        <span className='pop_total_list pop_price_main'>Fare details</span>
                                                                    </div>
                                                                    <div className='pop_total_card_single'>
                                                                        <span className='pop_total_list'>Total Fare <br />
                                                                        </span>
                                                                        <span className='pop_total_price'>{totelFare}</span>
                                                                    </div>
                                                                    <div className='pop_total_card_single'>
                                                                        <span className='pop_total_list'>Discounts
                                                                        </span>
                                                                        <span className='pop_total_price'> {couponDiscount ? <>- {parseInt(couponDiscount)}</> : '0'}</span>
                                                                    </div>
                                                                    <div className='pop_total_card_single total_price'>
                                                                        <span className='pop_total_list'>Total</span>
                                                                        <span className='pop_total_price'> {parseInt(finalTotal)}</span>
                                                                    </div>
                                                                </div>
                                                                <div className='facility_space'>
                                                                    {tempHotelDetails?.item.facilities.length ?
                                                                        <div className='col-lg-12 col-md-12'>
                                                                            <Box className='payment_form_head'>
                                                                                <h6 className="MuiTypography-root MuiTypography-h6">
                                                                                    Facility
                                                                                </h6>
                                                                            </Box>
                                                                            <div className='row'>
                                                                                {tempHotelDetails?.item.facilities?.map((item, ind) => (
                                                                                    <div className='col-lg-6 clo-md-6 col-sm-12'>
                                                                                        <div className='facility_list'>
                                                                                            <div className='facility_list_icon'>
                                                                                                <img src={hotelFacilityIcon} alt='' />
                                                                                            </div>
                                                                                            <h6 key={'tempHotelDetails' + ind}> {item}</h6>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                                <div className='hotel_fecility_sec'>
                                                                    <Box className='payment_form_head'>
                                                                        <h6 className="MuiTypography-root MuiTypography-h6">
                                                                            Stay details
                                                                        </h6>
                                                                    </Box>
                                                                    <ul>
                                                                        <li>{tempHotelDetails?.item.boardType}</li>
                                                                        <li>{tempHotelDetails?.item.extrabeds}</li>
                                                                        <li>{tempHotelDetails?.item.fareType}</li>
                                                                        <li>{tempHotelDetails?.item.inventoryType}</li>
                                                                        <li>{tempHotelDetails?.item.roomType}</li>
                                                                        <li>{tempHotelDetails?.item.cancellationPolicy} * The cancellation policy is setup by hotels, not by tickatrip *</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </div>
                                        </Box>
                                    </Container>
                                </div>
                            </Grid>
                        </Grid >
                    </Container >
                </Box >
            </div >
        </div>
    )
}

